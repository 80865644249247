import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { format, parse } from 'date-fns';
import { Document, MappedDocument } from 'src/app/models/dwrm/document';
import {
  HOURS_MAP,
  LastOrderPreview,
  OrderStatusUpdate,
  OrderStatuses,
} from 'src/app/models/dwrm/order';
import { InquiryElement } from 'src/app/models/inquiry';
import { Location, LocationData } from 'src/app/models/location';
import { Filter, NotificationVariants } from 'src/app/models/utils';
import { CompanyType } from 'src/app/shared/services/company.service';
import {
  InquiryWasteType,
  NestedEwcType,
} from 'src/app/shared/services/inquiry.service';

export function parseUTCDate(date: string): Date {
  return parse(date, 'yyyy-MM-dd', new Date());
}

export function formatDateAsUTC(date: string): string {
  return format(parse(date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy');
}

export function formatDate(date: string | Date): string {
  return format(typeof date === 'string' ? new Date(date) : date, 'dd.MM.yyyy');
}

export function formatTimestamp(date: string | Date): string {
  return format(
    typeof date === 'string' ? new Date(date) : date,
    'dd.MM.yyyy HH:mm'
  );
}

export function formatISODate(date: string | Date): string {
  if (!date) return '';
  return format(new Date(date), 'yyyy-MM-dd');
}

export function wrapText(text: string, maxLength: number): string {
  return text
    ? text.length > maxLength
      ? `${text.substring(0, maxLength - 3).trim()}...`
      : text
    : '';
}

export function formatStreet(location: Location | LocationData): string {
  if (!location) return '';
  if (location.region) return location.regionName;

  return `${location.street} ${location.streetNo}`;
}

export function formatAddress(location: Location | LocationData): string {
  if (!location) return '';
  if (location.region) return location.regionName;

  const street = formatStreet(location);
  const streetWithDoor = location.door ? `${street}/${location.door}` : street;

  return `${streetWithDoor}, ${location.zip} ${location.city}`;
}

export function formatLocation(location: Location | LocationData): string {
  if (!location) return '';
  if (location.region)
    return `${location.federalState}, ${location.country}, ${location.regionName}`;

  return `${location.city}, ${location.zip}, ${location.street} ${location.streetNo}`;
}

export function mapDocumentAttributes(document: Document): MappedDocument {
  const data = {};
  document.properties.forEach((attribute) => {
    data[attribute.key] = attribute.value;
  });
  return {
    ...document,
    data,
  };
}

export function mapFiltersToQueryParams(filters: Filter[]): string {
  return filters
    .map((filter) => {
      if (filter.value instanceof Date) {
        return `${filter.key}=${formatDate(filter.value)}`;
      } else if (Array.isArray(filter.value)) {
        return `${filter.key}=${filter.value.join(',')}`;
      } else {
        return `${filter.key}=${filter.value}`;
      }
    })
    .join('&');
}

export function capitalizeFirstLetter(string: string): string {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

@Injectable({
  providedIn: 'root',
})
export class GermanDateAdapter extends NativeDateAdapter {
  override parse(value: any): Date | null {
    if (typeof value === 'string' && value.indexOf('.') > -1) {
      const str = value.split('.');
      if (
        str.length < 2 ||
        isNaN(+str[0]) ||
        isNaN(+str[1]) ||
        isNaN(+str[2])
      ) {
        return this.invalid();
      }

      if (str[2].length === 2) {
        str[2] = '20' + str[2];
      }

      try {
        return super.createDate(
          Number(str[2]),
          Number(str[1]) - 1,
          Number(str[0])
        );
      } catch {
        return this.invalid();
      }
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  override format(
    date: Date,
    displayFormat: Intl.DateTimeFormatOptions
  ): string {
    // Force 2-digit dates
    if (displayFormat?.day === 'numeric') displayFormat.day = '2-digit';
    if (displayFormat?.month === 'numeric') displayFormat.month = '2-digit';
    return super.format(date, displayFormat);
  }
}

type SummedWasteQuantity = {
  kg: number;
  liter: number;
  tons: number;
  m3: number;
};

export function getTotalWasteQuantity(
  elements: InquiryElement[]
): SummedWasteQuantity {
  const result = elements.reduce(
    (acc, cur) => {
      switch (cur.unit) {
        // case 'kg':
        //   return { ...acc, kg: acc.kg + parseInt(cur.unit) };
        case 'kg':
          return { ...acc, kg: acc.kg + parseInt(cur.quantity) };
        case 'Tonne':
          return { ...acc, tons: acc.tons + parseInt(cur.quantity) * 1000 };
        case 'Liter':
          return { ...acc, liter: acc.liter + parseInt(cur.quantity) };
        case 'm³':
          return { ...acc, m3: acc.m3 + parseInt(cur.quantity) };
      }
    },
    { kg: 0, liter: 0, tons: 0, m3: 0 }
  );
  return result;
}

export function normalizeSearchTerm(value: string): string {
  if (!value) return '';
  return value.replace(/ /g, '').toLowerCase();
}

export function nestedEwcToString(type: NestedEwcType) {
  return `${type.ewc}: ${type.description}`;
}

export function getWasteTypeSN(waste: InquiryWasteType) {
  if (!waste) return '';
  return [waste.sn, waste.spec, waste.toxicity].join(' ').trimEnd();
}

export function wasteTypetoString(waste: InquiryWasteType) {
  if (!waste) return '';
  return `${[waste.sn, waste.spec, waste.toxicity].join(' ').trimEnd()}: ${
    waste.description
  }`;
}

export function mapConstLabels(
  constLabelsObject: Record<string, string>
): { value: string; label: string }[] {
  return Object.keys(constLabelsObject).map((key) => ({
    value: key,
    label: constLabelsObject[key],
  }));
}

export function formatOrderStatusLabel(order: LastOrderPreview, date: string) {
  if (order.status === 'ENTWURF') return 'Entwurf';
  if (order.status === 'BEAUFTRAGT') return `Beauftragt am ${date}`;
  if (order.status === 'DISPONIERT') return `Disponiert für ${date}`;
  if (order.status === 'UMDISPONIERT') return `Umdisponiert für ${date}`;
  if (order.status === 'AVISIERT') return `Avisiert für ${date}`;
  if (order.status === 'ABGEHOLT') return `Abgeholt um ${date}`;

  return '';
}

export function formatOrderNumber(orderNumber: number) {
  return `#${orderNumber.toString().padStart(5, '0')}`;
}

export function formatOrderTimeRange(from: string, to: string) {
  if (from !== 'undefined' && to !== 'undefined') {
    return `${HOURS_MAP[from]} - ${HOURS_MAP[to]} Uhr`;
  }
  return `Keine Zeitangabe`;
}

export function formatFullCollectionDate(
  collectionDate: Date,
  timeFrom: string,
  timeTo: string
) {
  return `${formatDate(collectionDate)} von ${formatOrderTimeRange(
    timeFrom,
    timeTo
  )}`;
}

export function getKeyFromEnumValue<T>(enumObj: T, value: T[keyof T]): keyof T {
  return (Object.keys(enumObj) as (keyof T)[]).find(
    (key) => enumObj[key] === value
  );
}

export function getAlertFormatByOrderStatusUpdate(
  statusUpdate: OrderStatusUpdate
): { title: string; message: string; type: NotificationVariants } {
  switch (OrderStatuses[statusUpdate.status]) {
    case OrderStatuses.DISPONIERT:
      return {
        title: 'Auftrag geplant',
        message: `Abholung geplant für ${statusUpdate.data}`,
        type: 'info',
      };
    case OrderStatuses.UMDISPONIERT:
      return {
        title: 'Auftrag umdisponiert',
        message: `Abholung umdisponiert für ${statusUpdate.data}`,
        type: 'warning',
      };
    case OrderStatuses.AVISIERT:
      return {
        title: 'Auftrag avisiert',
        message: `Abholung avisiert für ${formatTimestamp(
          statusUpdate.timestamp
        )}`,
        type: 'info',
      };
    case OrderStatuses.ABGEHOLT:
      return {
        title: 'Auftrag abgeholt',
        message: `Abholung abgeholt um ${formatTimestamp(
          statusUpdate.timestamp
        )}`,
        type: 'success',
      };
    case OrderStatuses.ENTLADEN:
      return {
        title: 'Auftrag entladen',
        message: `Abholung entladen um ${formatTimestamp(
          statusUpdate.timestamp
        )}`,
        type: 'success',
      };
    case OrderStatuses.VERRECHNET:
      return {
        title: 'Auftrag verrechnet',
        message: `Abholung verrechnet um ${formatTimestamp(
          statusUpdate.timestamp
        )}`,
        type: 'success',
      };
    default:
      return {
        title: 'Not implemented yet',
        message: `Not implemented yet`,
        type: 'info',
      };
  }
}

export function companyTypeToString(type: CompanyType) {
  switch (type) {
    case 'WASTE_DISPOSAL':
      return 'Behandler';
    case 'SUPPLIER':
      return 'Anbieter';
    case 'TRANSPORT':
      return 'Transport';
    case 'CONVERTER':
      return 'Umwandler';
    case 'WASTE_PRODUCER':
      return 'Erzeuger';
    case 'WASTE_COLLECTOR':
      return 'Sammler';
    case 'WASTE_CONSULTANT':
      return 'Berater';
  }
}

export function convertPreferredContractDuration(type: string): string {
  switch (type) {
    case null:
      return 'Keine Angabe';
    case 'P1Y':
      return '1 Jahr';
    case 'P2Y':
      return '2 Jahre';
    case 'P3Y':
      return '3 Jahre';
    default:
      return '';
  }
}
